import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  SubmitReversedButton,
  Box,
  Flex,
} from "./ui"

export function SubscribeForm() {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const response = await addToMailchimp(input)
    setSuccessMsg(response.msg)
  }

  return (
    <Box>
      {!successMsg && (
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "4px" }}>
            <label>Email address</label>
          </div>
          <Flex>
            <input
              value={input}
              type="email"
              name="email"
              placeholder="Enter Email"
              onChange={(e) => setInput(e.currentTarget.value)}
              disabled={isLoading}
              size="50"
              style={{ height: "40px", maxWidth: 200 }}
            />
            <SubmitReversedButton type="submit">Subscribe</SubmitReversedButton>
          </Flex>
        </form>
      )}
      {successMsg && <div>{successMsg}</div>}
    </Box>
  )
}
