import React from "react"
import Layout from "../components/layout"
import { SubscribeForm } from '../components/subscribe-form';
import { Container, Section, Button } from "../components/ui"

export default function Contact() {
  return (
    <Layout>
      <Section>
        <Container>
          <h1>Stay connected with us</h1>
          <p style={{ marginBottom: 0 }}>
            Receive the latest on iwn studio: news, careers and projects in your
            email inbox.
          </p>
          <br />
          <SubscribeForm />
          <br />
          <br />
          <p>
            To learn more about the studio, please reach out at{" "}
            <a href="mailto:info@iwn.studio">info@iwn.studio</a>
          </p>
        </Container>
      </Section>
    </Layout>
  )
}
